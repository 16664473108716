.profileDetailsPresentation {
    overflow: hidden;
}

.pitchDeckTitle {
    margin: 4rem 0;
    text-align: center;
}

// .pdfSize {
//     height: 750px;
// }
