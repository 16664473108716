.ppm_documentation {
    padding: 2rem 4rem;

    h4 {
        margin-bottom: 2rem;
    }

    &_flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .button_ppm {
        display: block;
        border: 1px solid #b5b5b5;
        color: #999;
        padding: 1.4rem;
        border-radius: 1rem;
        width: 100%;
        text-align: left;
        font-weight: 700;
        margin-bottom: 1.2rem;
        display: block !important;
        line-height: 1;

        display: flex;
        align-items: middle;

        i {
            font-size: 2rem;
            padding-right: 1rem;
            vertical-align: middle;
        }

        &:hover {
            border: 1px solid #469AD4; 
        }
    }
}
