.home_header {

    position: relative;
    display: flex;
    // align-items: center;
    // justify-content: center;
    background-position: center;
    height: 500px;

    @media all and (max-width: 1100px) {
        height: auto;
    }

    img {
        width: 100%;
        display: block;
    }

    &_content {
        background: rgba(0,0,0,.8);
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding-top: 4rem;
        padding-bottom: 4rem;
       
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media all and (max-width: 1100px) {
            width: 100%;
            background: rgba(0,0,0,.8);
        }

        &_logo {
            padding: 0rem 8rem;
            margin-bottom: 3rem;
            
            @media all and (max-width: 750px) {
                padding: 0rem 4rem;
                margin-bottom: 2rem;
            }


            &_follow {
                font-size: 4rem;            
                span {
                    &:last-child {
                        font-size: 2rem;
                    }
                }

                @media all and (max-width: 750px) {
                    margin-right: 8rem;
                }
            }
        
            &_investments {
                font-size: 2rem;
                font-weight: 600;
        
                &::before {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
            }
        }

        &_cta {
            padding: 0rem 8rem;
            font-size: 2rem;
            margin-top: 2rem;
            font-weight: 400;
            line-height: 1.2;
            color: #fefefe;
            text-align: center;
            display: none;

            @media all and (max-width: 750px) {
                padding: 0rem 4rem;
                font-size: 2.2rem;
            }

            // @media all and (max-width: 550px) {
            //     font-size: 1.6rem;
            // }
        }

        .cta {
            color: #fff;
            font-size: 2rem;
            max-width: 800px;
            padding-left: 2rem;
            padding-right: 2rem;
            text-align: center;
        }

        &_buttons {
            padding: 0 8rem;
            margin-top: 4rem;

            display: flex;
            flex-direction: column;
            align-items: center;

            @media all and (max-width: 750px) {
                margin-top: 2rem;
            }

            @media all and (max-width: 750px) {
                padding: 0rem 4rem;
            }
            
            a {
                margin: 0 !important;
            }

            button {
                &:not(:last-child) {
                    margin-right: 0 !important;
                }
            }

            .lgBtn {
                a {
                    font-size: 1.8rem;
                    padding: 24px 30px;
                }
            }

            .linkBtn {
                margin-top: 1rem;

                button {
                    border: 0 !important;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
        }
    }
}